import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityCollectionDataProvider } from '@entity-framework/entity-collection/collection-data-provider/abstract-entity-collection.data-provider';
import { environment } from '@env/environment';
import { LoggingService } from '@logging/logging.service';
import { Employer } from './employer';

@Injectable({ providedIn: 'root' })
export class EmployersDataProvider extends AbstractEntityCollectionDataProvider<Employer> {
  protected apiRoot = `${environment.apiPaths.employers}`;
  constructor(
    protected httpClient: HttpClient,
    protected logger: LoggingService
  ) {
    super(httpClient, logger);
  }

  setParentPath(parentId: string) {
    this.parentPath = undefined;
  }
}
